import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LegacyCard } from '@shopify/polaris';
import { BuildRenderUrl } from '../../../Utils.tsx';
import { HOST } from '../../../Constants.ts';
export default function AllCard({ sharding_key, template_id, secret_key, editableFields, }) {
    const render_url = `https://w${sharding_key}.previewbuilder.com/api/r/${template_id}?${BuildRenderUrl(HOST, template_id, secret_key, editableFields).split('?')[1]}`;
    return (_jsxs(LegacyCard.Section, { children: [_jsxs("p", { children: ["Look for guide on", ' ', _jsx("a", { target: '_blank', href: 'https://previewbuilder.com/guides/', children: "https://previewbuilder.com/guides" }), ' ', "or just copy instruction below to ChatGPT and ask AI how to integrate this snippet to your language/framework/platform :-)"] }), _jsxs("code", { style: {
                    wordBreak: 'break-all',
                    whiteSpace: 'break-spaces',
                }, children: ["You need to add those meta tags to your HTML on render time.", _jsx("br", {}), _jsx("br", {}), _jsxs("b", { children: ["<meta property=\"og:image\" content=\"{render_url}\" />", ' '] }), _jsx("br", {}), _jsx("b", { children: "<meta property=\"og:image:width\" content=\"1200\" />" }), _jsx("br", {}), _jsx("b", { children: "<meta property=\"og:image:height\" content=\"628\" />" }), _jsx("br", {}), " ", _jsx("br", {}), "render_url is a dynamic variable. To build url you just follow this easy steps:", _jsx("br", {}), _jsx("br", {}), "1. Take template base path:", _jsx("br", {}), _jsx("b", { children: "path" }), " =", ' ', `https://w${sharding_key}.previewbuilder.com/api/r/${template_id}`, _jsx("br", {}), _jsx("br", {}), "2. Fill variables: ", _jsx("br", {}), editableFields.map((f, i) => {
                        return `${f.name}=encodeURIComponent("${f.value}")${i !== editableFields.length - 1 ? `&` : ''}\r\n`;
                    }), _jsx("br", {}), _jsx("br", {}), "3. Calculate query part: ", _jsx("br", {}), _jsx("b", { children: "query" }), " =", ' ', editableFields.map((f, i) => {
                        return `${f.name}=encodeURIComponent("${f.value}")${i !== editableFields.length - 1 ? `&` : ''}`;
                    }), _jsx("br", {}), _jsx("br", {}), "4. Calculate signature. It is necessary to avoid using your rendering credits by 3rd party.", _jsx("br", {}), _jsx("b", { children: "your_secret_key" }), " = \"", secret_key, "\"", _jsx("br", {}), _jsx("b", { children: "signature" }), " = crypto.createHmac('sha256', your_secret_key).update(query).digest('hex');", _jsx("br", {}), _jsx("br", {}), "5. Build your image url together:", _jsx("br", {}), _jsx("b", { children: "url = path + '?' + query + '&s=' + signature" }), _jsx("br", {}), _jsx("br", {}), _jsx("br", {}), "Your result for those params must be:", _jsx("br", {}), _jsx("span", { style: { wordBreak: 'break-all', fontWeight: 500 }, children: _jsx("b", { children: render_url }) }), _jsx("br", {}), _jsx("br", {}), "Feel free to ask any questions in support chat!", _jsx("br", {}), _jsx("br", {})] })] }));
}
