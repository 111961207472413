import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LegacyCard } from '@shopify/polaris';
export default function BigcommerceCard({ sharding_key, template_id, secret_key, simple_key, editableFields, }) {
    return (_jsxs(LegacyCard.Section, { children: [_jsxs("p", { children: ["To complete integration you will need to insert some code into your bigcommerce store theme. You can insert code below AS IS and to disable integration just revert the changes. Read our guide how to insert code snippet in bigcommerce store theme", ' ', _jsx("a", { target: '_blank', href: 'https://previewbuilder.com/guides/bigcommerce', children: "https://previewbuilder.com/guides/bigcommerce" })] }), _jsxs("code", { style: {
                    wordBreak: 'break-all',
                    whiteSpace: 'break-spaces',
                }, children: ["// As mentioned in our guide you should replace {{{head.meta_tags }}} with following code", _jsx("br", {}), `
{{#each (split head.meta_tags "<meta") }}
  {{#if @index '===' 0 }}
    {{{this}}}
  {{else }}
    {{#contains this 'property="og:image"'}}
      {{#unless page_type '==' product}}
      <meta {{{this}}}
      {{else}}
          <meta property="og:image" content="https://w${sharding_key}.previewbuilder.com/api/r/${template_id}?${editableFields
                        .map((f) => {
                        if (f.name.indexOf('Image') === 0) {
                            return (f.name +
                                '=' +
                                "{{getImage @root.product.main_image 'product_size' (cdn theme_settings.default_image_product)}}");
                        }
                        else if (f.name.indexOf('Text') === 0) {
                            if (['$', '€', 'usde', 'price'].find((v) => f.value.indexOf(v) !== -1)) {
                                return (f.name +
                                    '=' +
                                    '{{encodeURI @root.product.price.with_tax.formatted}}');
                            }
                            else if (['title', 'Ribbed'].find((v) => f.value.indexOf(v) !== -1)) {
                                return f.name + '=' + '{{getImage @root.product.title}}';
                            }
                            else {
                                return f.name + '=' + f.value;
                            }
                        }
                        else {
                            return '';
                        }
                    })
                        .join('&')}&s=${simple_key}" >
      {{/unless}}
    {{else}}
      <meta {{{this}}}
    {{/contains}}
  {{/if}}
{{/each}}
<meta property="og:image:width" content="1200">
<meta property="og:image:height" content="628">


// Feel free to ask any questions in support chat!
`] })] }));
}
