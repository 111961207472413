import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LegacyCard } from '@shopify/polaris';
import { BuildRenderUrl } from '../../../Utils.tsx';
import { HOST } from '../../../Constants.ts';
export default function PhpCard({ sharding_key, template_id, secret_key, editableFields, }) {
    const render_url = `https://w${sharding_key}.previewbuilder.com/api/r/${template_id}?${BuildRenderUrl(HOST, template_id, secret_key, editableFields).split('?')[1]}`;
    return (_jsxs(LegacyCard.Section, { children: [_jsxs("p", { children: ["Read the guide", ' ', _jsx("a", { target: '_blank', href: 'https://previewbuilder.com/guides/php', children: "https://previewbuilder.com/guides/php" }), ". For this integration you will need some coding skills or programmer."] }), _jsx("code", { style: {
                    wordBreak: 'break-all',
                    whiteSpace: 'break-spaces',
                }, children: `<?php
/**
 * Function to create a signed URL for PreviewBuilder API
 *
 * @param string $basePath Base API path
 * @param array $params Parameters for the URL
 * @param string $secretKey Secret key for signature generation
 * @return string Complete URL with signature
 */
function buildRenderUrl($basePath, $params, $secretKey) {
    // Step 1: Use the base path
    $path = $basePath;

    // Steps 2 and 3: Form query parameters
    $queryParts = [];
    foreach ($params as $key => $value) {
        $encodedValue = urlencode($value);
        $queryParts[] = $key . '=' . $encodedValue;
    }
    $query = implode('&', $queryParts);

    // Step 4: Calculate signature
    $signature = hash_hmac('sha256', $query, $secretKey);

    // Step 5: Build the URL together
    $url = $path . '?' . $query . '&s=' . $signature;
    return $url;
}

/**
 * Adds meta tags for social networks with dynamic image URL
 *
 * @param string $shardingKey The shard identifier for PreviewBuilder
 * @param string $templateId The template identifier
 * @param string $secretKey Secret key for signature generation
 * @param array $editableFields Fields that can be edited in the template
 */
function addMetaTags($shardingKey, $templateId, $secretKey, $editableFields) {
    // Construct the base path from parameters
    $basePath = "https://{$shardingKey}.previewbuilder.com/api/r/{$templateId}";

    // Get the complete URL
    $renderUrl = buildRenderUrl($basePath, $editableFields, $secretKey);

    // Add meta tags to HTML
    echo "<!-- Social media meta tags -->\\n";
    echo "<meta property=\\"og:image\\" content=\\"{$renderUrl}\\" />\\n";
    echo "<meta property=\\"og:image:width\\" content=\\"1200\\" />\\n";
    echo "<meta property=\\"og:image:height\\" content=\\"628\\" />\\n";
    echo "<meta name=\\"twitter:card\\" content=\\"summary_large_image\\" />\\n";
    echo "<meta name=\\"twitter:image\\" content=\\"{$renderUrl}\\" />\\n";
}

// Example usage when rendering the page
function renderPage() {
    // Configuration parameters
    $shardingKey = "w${sharding_key}"; // PreviewBuilder shard identifier
    $templateId = "${template_id}"; // Template ID
    $secretKey = "${secret_key}"; // Secret key

    $editableFields = [
      ${editableFields
                    .map((f, i) => {
                    return `'${f.name}' => '${f.value}'`;
                })
                    .join(',\r\n      ')}
    ];
    ?>
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Your title</title>
    <?php addMetaTags($shardingKey, $templateId, $secretKey, $editableFields); ?>
</head>
<body>
    <!-- Page content -->
</body>
</html>
    <?php
}

// Call the page rendering function
renderPage();
?>` })] }));
}
