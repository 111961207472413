import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { LegacyCard, Tabs } from '@shopify/polaris';
import AllCard from './cards/all.tsx';
import PhpCard from './cards/php.tsx';
import NodeCard from './cards/nodejs.tsx';
import ShopifyCard from './cards/shopify.tsx';
import BigcommerceCard from './cards/bigcommerce.tsx';
function IntegrateCard({ sharding_key, template_id, secret_key, simple_key, editableFields, }) {
    const [selected, setSelected] = useState(0);
    const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), []);
    const tabs = [
        {
            id: 'all',
            content: 'All',
            accessibilityLabel: 'All site types instruction',
        },
        {
            id: 'php',
            content: 'Php',
        },
        {
            id: 'nodejs',
            content: 'Nodejs',
        },
        {
            id: 'shopify',
            content: 'Shopify',
        },
        {
            id: 'bigcommerce',
            content: 'Bigcommerce',
        },
    ];
    return (_jsx(LegacyCard, { children: _jsxs(Tabs, { tabs: tabs, selected: selected, onSelect: handleTabChange, children: [tabs[selected].id === 'all' && (_jsx(AllCard, { template_id: template_id, sharding_key: sharding_key, editableFields: editableFields, secret_key: secret_key })), tabs[selected].id === 'php' && (_jsx(PhpCard, { template_id: template_id, sharding_key: sharding_key, editableFields: editableFields, secret_key: secret_key })), tabs[selected].id === 'nodejs' && (_jsx(NodeCard, { template_id: template_id, sharding_key: sharding_key, editableFields: editableFields, secret_key: secret_key })), tabs[selected].id === 'shopify' && (_jsx(ShopifyCard, { template_id: template_id, sharding_key: sharding_key, editableFields: editableFields, secret_key: secret_key })), tabs[selected].id === 'bigcommerce' && (_jsx(BigcommerceCard, { template_id: template_id, sharding_key: sharding_key, editableFields: editableFields, secret_key: secret_key, simple_key: simple_key }))] }) }));
}
export default IntegrateCard;
