import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LegacyCard } from '@shopify/polaris';
import { BuildRenderUrl } from '../../../Utils.tsx';
import { HOST } from '../../../Constants.ts';
export default function NodeCard({ sharding_key, template_id, secret_key, editableFields, }) {
    const render_url = `https://w${sharding_key}.previewbuilder.com/api/r/${template_id}?${BuildRenderUrl(HOST, template_id, secret_key, editableFields).split('?')[1]}`;
    return (_jsxs(LegacyCard.Section, { children: [_jsxs("p", { children: ["Read our guide", ' ', _jsx("a", { target: '_blank', href: 'https://previewbuilder.com/guides/nodejs', children: "https://previewbuilder.com/guides/nodejs" }), ". For this integration you will need some coding skills or programmer."] }), _jsxs("code", { style: {
                    wordBreak: 'break-all',
                    whiteSpace: 'break-spaces',
                }, children: ["You need to add those meta tags to your HTML on render time. Will work fine with server side rendering like nextjs/ejs/etc.", _jsx("br", {}), _jsx("br", {}), _jsxs("b", { children: ["<meta property=\"og:image\" content=\"{render_url}\" />", ' '] }), _jsx("br", {}), _jsx("b", { children: "<meta property=\"og:image:width\" content=\"1200\" />" }), _jsx("br", {}), _jsx("b", { children: "<meta property=\"og:image:height\" content=\"628\" />" }), _jsx("br", {}), " ", _jsx("br", {}), "render_url is a dynamic variable.", _jsx("br", {}), _jsx("br", {}), `
import crypto from 'crypto';
const template_id = "${template_id}";
const path = \`https://w${sharding_key}.previewbuilder.com/api/r/${template_id}\`
const your_secret_key = "b71e7d611639e825a133d654f0f1ba86fe9c48ef01204a237f182a48bfd5b18d"

${editableFields
                        .map((f) => {
                        return `const ${f.name} = "${f.value}";`;
                    })
                        .join('\r\n')}

const query = \`${editableFields
                        .map((f) => {
                        return f.name + `=\${encodeURIComponent(${f.name})}`;
                    })
                        .join('&')}\`;

const signature = crypto.createHmac('sha256', your_secret_key).update(query).digest('hex');
const render_url = path + '?' + query + '&s=' + signature;
// insert meta tags with render_url into &lt;head /&rt; section.
// Feel free to ask any questions in support chat!
`, _jsx("br", {}), _jsx("br", {})] })] }));
}
