import jsSHA from 'jssha';
export function BuildRenderUrl(domain, template_id, secret_key, data_to_replace) {
    const url = `${domain}/api/render/${template_id}`;
    const query = data_to_replace
        .map((d) => `${d.name}=${encodeURIComponent(d.value)}`)
        .join('&');
    const shaObj = new jsSHA('SHA-256', 'TEXT', {
        hmacKey: {
            value: secret_key,
            format: 'TEXT',
            encoding: 'UTF8',
        },
        encoding: 'UTF8',
    });
    const signature = shaObj.update(query).getHash('HEX');
    return `${url}?${query}&s=${signature}`;
    // const url = `https://${domain}/api/render/${template_id}`;
    // const query = data_to_replace
    //   .map((d) => `${d.name}=${encodeURIComponent(d.value)}`)
    //   .join('&');
    // const signature = crypto
    //   .createHash('sha256')
    //   .update(query + secret_key)
    //   .digest('hex');
    // return `${url}?${query}&s=${signature}`;
}
export const predefinied_templates = [
    {
        width: 1200,
        height: 628,
        state: [
            {
                id: 2,
                type: 'Rect',
                color: '#000000',
                opacity: 1,
                position: { x: -22, y: -19 },
                rotationAngle: 0,
                size: { width: 1228, height: 655 },
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
            },
            {
                id: 1,
                type: 'Image',
                opacity: 1,
                position: { x: 557, y: -6 },
                rotationAngle: 0,
                size: { width: 647, height: 635 },
                crop: { x: 0, y: 0, width: 1, height: 1 },
                link: 'https://app.previewbuilder.com/ribbed_knit.jpeg',
                imageSizeMode: 'ContainResizeRight',
                imageType: 'Uploaded',
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
            },
            {
                id: 3,
                type: 'Text',
                text: 'Ribbed Knit Bodycon Maxi Dress',
                fontFamily: 'Montserrat',
                fontWeight: 'Semi Bold',
                color: '#ffffff',
                fontSize: 46,
                opacity: 1,
                position: { x: 22, y: 20 },
                rotationAngle: 0,
                size: { width: 509, height: 325 },
                verticalAlign: 'Bottom',
                horizontalAlign: 'Left',
                horizontalLetterSpacing: 0,
                verticalLetterSpacing: 0,
                wrap: false,
                smartCrop: 'Resize',
                backgroundColor: 'none',
                backgroundOpacity: 1,
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
                leftPadding: 0,
                rightPadding: 0,
                topPadding: 0,
                bottomPadding: 0,
            },
            {
                id: 4,
                type: 'Text',
                text: '<sup>$</sup>49<sup>99</sup>',
                fontFamily: 'Montserrat',
                fontWeight: 'Bold',
                color: '#000000',
                fontSize: 68,
                opacity: 1,
                position: { x: 25, y: 340 },
                rotationAngle: 0,
                size: { width: 149, height: 103 },
                verticalAlign: 'Middle',
                horizontalAlign: 'Center',
                horizontalLetterSpacing: 0,
                verticalLetterSpacing: -21,
                wrap: true,
                smartCrop: 'None',
                backgroundColor: '#ebff00',
                backgroundOpacity: 1,
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 1,
                borderRadius: 13,
                borderStyle: 'solid',
                leftPadding: 20,
                rightPadding: 20,
                topPadding: 0,
                bottomPadding: 0,
            },
            {
                id: 5,
                type: 'Text',
                text: 'luxury_store.com',
                fontFamily: 'Montserrat',
                fontWeight: 'Light',
                color: '#e0e0e0',
                fontSize: 46,
                opacity: 1,
                position: { x: 22, y: 547 },
                rotationAngle: 0,
                size: { width: 524, height: 63 },
                verticalAlign: 'Bottom',
                horizontalAlign: 'Left',
                horizontalLetterSpacing: 0,
                verticalLetterSpacing: 0,
                wrap: false,
                smartCrop: 'Resize',
                backgroundColor: 'none',
                backgroundOpacity: 1,
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
                leftPadding: 0,
                rightPadding: 0,
                topPadding: 0,
                bottomPadding: 0,
            },
        ],
    },
    {
        width: 1200,
        height: 628,
        state: [
            {
                id: 2,
                type: 'Rect',
                color: '#24251F',
                opacity: 1,
                position: { x: -8, y: -10 },
                rotationAngle: 0,
                size: { width: 1217, height: 646 },
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
            },
            {
                id: 3,
                type: 'Rect',
                color: '#D92B2E',
                opacity: 1,
                position: { x: 935, y: -9 },
                rotationAngle: 0,
                size: { width: 265, height: 650 },
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
            },
            {
                id: 1,
                type: 'Image',
                opacity: 1,
                position: { x: 424, y: 0 },
                rotationAngle: 0,
                size: { width: 689, height: 628 },
                crop: { x: 0, y: 0, width: 1, height: 1 },
                link: 'https://app.previewbuilder.com/choker.jpg',
                imageSizeMode: 'ContainResizeRight',
                imageType: 'Uploaded',
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
            },
            {
                id: 4,
                type: 'Text',
                text: 'luxurystore.com',
                fontFamily: 'Alexandria',
                fontWeight: 'Regular',
                color: '#ffffff',
                fontSize: 46,
                opacity: 1,
                position: { x: 846, y: 274 },
                rotationAngle: 1.57,
                size: { width: 616, height: 79 },
                verticalAlign: 'Middle',
                horizontalAlign: 'Center',
                horizontalLetterSpacing: 0,
                verticalLetterSpacing: 0,
                wrap: false,
                smartCrop: 'Resize',
                backgroundColor: 'none',
                backgroundOpacity: 1,
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
                leftPadding: 0,
                rightPadding: 0,
                topPadding: 0,
                bottomPadding: 0,
            },
            {
                id: 5,
                type: 'Text',
                text: 'Title and description of your product',
                fontFamily: 'Alexandria',
                fontWeight: 'Medium',
                color: '#ffffff',
                fontSize: 42,
                opacity: 1,
                position: { x: 26, y: 24 },
                rotationAngle: 0,
                size: { width: 361, height: 503 },
                verticalAlign: 'Top',
                horizontalAlign: 'Left',
                horizontalLetterSpacing: 0,
                verticalLetterSpacing: 0,
                wrap: false,
                smartCrop: 'Resize',
                backgroundColor: 'none',
                backgroundOpacity: 1,
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
                leftPadding: 0,
                rightPadding: 0,
                topPadding: 0,
                bottomPadding: 0,
            },
            {
                id: 6,
                type: 'Text',
                text: '$69.99',
                fontFamily: 'Alexandria',
                fontWeight: 'Bold',
                color: '#ffffff',
                fontSize: 48,
                opacity: 1,
                position: { x: 25, y: 534 },
                rotationAngle: 0,
                size: { width: 358, height: 81 },
                verticalAlign: 'Top',
                horizontalAlign: 'Left',
                horizontalLetterSpacing: 0,
                verticalLetterSpacing: 0,
                wrap: false,
                smartCrop: 'Resize',
                backgroundColor: 'none',
                backgroundOpacity: 1,
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
                leftPadding: 0,
                rightPadding: 0,
                topPadding: 0,
                bottomPadding: 0,
            },
        ],
    },
    {
        width: 1200,
        height: 628,
        state: [
            {
                id: 1,
                type: 'Image',
                opacity: 1,
                position: { x: -8, y: -8 },
                rotationAngle: 0,
                size: { width: 1212, height: 629 },
                crop: { x: 0, y: 0, width: 1, height: 1 },
                link: 'https://app.previewbuilder.com/forest.jpg',
                imageSizeMode: 'Cover',
                imageType: 'Uploaded',
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
            },
            {
                id: 2,
                type: 'Rect',
                color: '#000000',
                opacity: 0.26,
                position: { x: -19, y: -19 },
                rotationAngle: 0,
                size: { width: 1223, height: 650 },
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
            },
            {
                id: 3,
                type: 'Text',
                text: 'Good template for media and blog. Just put your title here and use article photo as background.',
                fontFamily: 'Alexandria',
                fontWeight: 'Regular',
                color: '#ffffff',
                fontSize: 90,
                opacity: 1,
                position: { x: 24, y: 29 },
                rotationAngle: 0,
                size: { width: 1150, height: 517 },
                verticalAlign: 'Middle',
                horizontalAlign: 'Center',
                horizontalLetterSpacing: 0,
                verticalLetterSpacing: 0,
                wrap: false,
                smartCrop: 'Resize',
                backgroundColor: 'none',
                backgroundOpacity: 1,
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
                leftPadding: 0,
                rightPadding: 0,
                topPadding: 0,
                bottomPadding: 0,
            },
            {
                id: 4,
                type: 'Text',
                text: 'MarsTimes.com',
                fontFamily: 'Alexandria',
                fontWeight: 'Regular',
                color: '#ffffff',
                fontSize: 57,
                opacity: 1,
                position: { x: 777, y: 557 },
                rotationAngle: 0,
                size: { width: 419, height: 64 },
                verticalAlign: 'Middle',
                horizontalAlign: 'Center',
                horizontalLetterSpacing: 0,
                verticalLetterSpacing: 0,
                wrap: false,
                smartCrop: 'Resize',
                backgroundColor: 'none',
                backgroundOpacity: 1,
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
                leftPadding: 0,
                rightPadding: 0,
                topPadding: 0,
                bottomPadding: 0,
            },
        ],
    },
    {
        width: 1200,
        height: 628,
        state: [
            {
                id: 2,
                type: 'Text',
                text: 'rust-lang/rust',
                fontFamily: 'Sans serif',
                fontWeight: 'Regular',
                color: '#000000',
                fontSize: 86,
                opacity: 1,
                position: { x: 78, y: 104 },
                rotationAngle: 0,
                size: { width: 836, height: 108 },
                verticalAlign: 'Middle',
                horizontalAlign: 'Left',
                horizontalLetterSpacing: 0,
                verticalLetterSpacing: 0,
                wrap: true,
                smartCrop: 'Crop',
                backgroundColor: 'none',
                backgroundOpacity: 1,
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
                leftPadding: 0,
                rightPadding: 0,
                topPadding: 0,
                bottomPadding: 0,
            },
            {
                id: 3,
                type: 'Text',
                text: 'Empowering everyone to build reliable and efficient software.',
                fontFamily: 'Sans serif',
                fontWeight: 'Regular',
                color: '#6B7479',
                fontSize: 34,
                opacity: 1,
                position: { x: 75, y: 239 },
                rotationAngle: 0,
                size: { width: 795, height: 233 },
                verticalAlign: 'Top',
                horizontalAlign: 'Left',
                horizontalLetterSpacing: 0,
                verticalLetterSpacing: 0,
                wrap: false,
                smartCrop: 'Resize',
                backgroundColor: 'none',
                backgroundOpacity: 1,
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
                leftPadding: 0,
                rightPadding: 0,
                topPadding: 0,
                bottomPadding: 0,
            },
            {
                id: 4,
                type: 'Image',
                opacity: 1,
                position: { x: 917, y: 106 },
                rotationAngle: 0,
                size: { width: 232, height: 198 },
                crop: { x: 0, y: 0, width: 1, height: 1 },
                link: 'https://app.previewbuilder.com/rust.jpg',
                imageSizeMode: 'Contain',
                imageType: 'Uploaded',
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
            },
            {
                id: 5,
                type: 'Rect',
                color: '#DDA584',
                opacity: 1,
                position: { x: -7, y: 597 },
                rotationAngle: 0,
                size: { width: 1212, height: 35 },
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
            },
            {
                id: 6,
                type: 'Text',
                text: 'Contributors',
                fontFamily: 'Sans serif',
                fontWeight: 'Regular',
                color: '#6B7479',
                fontSize: 27,
                opacity: 1,
                position: { x: 124, y: 524 },
                rotationAngle: 0,
                size: { width: 232, height: 44 },
                verticalAlign: 'Top',
                horizontalAlign: 'Left',
                horizontalLetterSpacing: 0,
                verticalLetterSpacing: 0,
                wrap: false,
                smartCrop: 'Resize',
                backgroundColor: 'none',
                backgroundOpacity: 1,
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
                leftPadding: 0,
                rightPadding: 0,
                topPadding: 0,
                bottomPadding: 0,
            },
            {
                id: 7,
                type: 'Text',
                text: 'Stars',
                fontFamily: 'Sans serif',
                fontWeight: 'Regular',
                color: '#6B7479',
                fontSize: 27,
                opacity: 1,
                position: { x: 363, y: 524 },
                rotationAngle: 0,
                size: { width: 232, height: 44 },
                verticalAlign: 'Top',
                horizontalAlign: 'Left',
                horizontalLetterSpacing: 0,
                verticalLetterSpacing: 0,
                wrap: false,
                smartCrop: 'Resize',
                backgroundColor: 'none',
                backgroundOpacity: 1,
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
                leftPadding: 0,
                rightPadding: 0,
                topPadding: 0,
                bottomPadding: 0,
            },
            {
                id: 8,
                type: 'Text',
                text: 'Forks',
                fontFamily: 'Sans serif',
                fontWeight: 'Regular',
                color: '#6B7479',
                fontSize: 27,
                opacity: 1,
                position: { x: 552, y: 524 },
                rotationAngle: 0,
                size: { width: 232, height: 44 },
                verticalAlign: 'Top',
                horizontalAlign: 'Left',
                horizontalLetterSpacing: 0,
                verticalLetterSpacing: 0,
                wrap: false,
                smartCrop: 'Resize',
                backgroundColor: 'none',
                backgroundOpacity: 1,
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
                leftPadding: 0,
                rightPadding: 0,
                topPadding: 0,
                bottomPadding: 0,
            },
            {
                id: 9,
                type: 'Image',
                opacity: 1,
                position: { x: 83, y: 485 },
                rotationAngle: 0,
                size: { width: 35, height: 34 },
                crop: { x: 0, y: 0, width: 1, height: 1 },
                link: 'https://app.previewbuilder.com/contributors.png',
                imageSizeMode: 'Contain',
                imageType: 'Uploaded',
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
            },
            {
                id: 10,
                type: 'Image',
                opacity: 1,
                position: { x: 326, y: 489 },
                rotationAngle: 0,
                size: { width: 38, height: 31 },
                crop: { x: 0, y: 0, width: 1, height: 1 },
                link: 'https://app.previewbuilder.com/stars.png',
                imageSizeMode: 'Contain',
                imageType: 'Uploaded',
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
            },
            {
                id: 11,
                type: 'Image',
                opacity: 1,
                position: { x: 518, y: 489 },
                rotationAngle: 0,
                size: { width: 30, height: 29 },
                crop: { x: 0, y: 0, width: 1, height: 1 },
                link: 'https://app.previewbuilder.com/forks.png',
                imageSizeMode: 'Contain',
                imageType: 'Uploaded',
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
            },
            {
                id: 12,
                type: 'Text',
                text: '5k',
                fontFamily: 'Sans serif',
                fontWeight: 'Regular',
                color: '#000000',
                fontSize: 28,
                opacity: 1,
                position: { x: 125, y: 484 },
                rotationAngle: 0,
                size: { width: 144, height: 33 },
                verticalAlign: 'Top',
                horizontalAlign: 'Left',
                horizontalLetterSpacing: 0,
                verticalLetterSpacing: 0,
                wrap: false,
                smartCrop: 'Resize',
                backgroundColor: 'none',
                backgroundOpacity: 1,
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
                leftPadding: 0,
                rightPadding: 0,
                topPadding: 0,
                bottomPadding: 0,
            },
            {
                id: 13,
                type: 'Text',
                text: '93k',
                fontFamily: 'Sans serif',
                fontWeight: 'Regular',
                color: '#000000',
                fontSize: 28,
                opacity: 1,
                position: { x: 367, y: 486 },
                rotationAngle: 0,
                size: { width: 144, height: 33 },
                verticalAlign: 'Top',
                horizontalAlign: 'Left',
                horizontalLetterSpacing: 0,
                verticalLetterSpacing: 0,
                wrap: false,
                smartCrop: 'Resize',
                backgroundColor: 'none',
                backgroundOpacity: 1,
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
                leftPadding: 0,
                rightPadding: 0,
                topPadding: 0,
                bottomPadding: 0,
            },
            {
                id: 14,
                type: 'Text',
                text: '12k',
                fontFamily: 'Sans serif',
                fontWeight: 'Regular',
                color: '#000000',
                fontSize: 28,
                opacity: 1,
                position: { x: 553, y: 485 },
                rotationAngle: 0,
                size: { width: 144, height: 33 },
                verticalAlign: 'Top',
                horizontalAlign: 'Left',
                horizontalLetterSpacing: 0,
                verticalLetterSpacing: 0,
                wrap: false,
                smartCrop: 'Resize',
                backgroundColor: 'none',
                backgroundOpacity: 1,
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
                leftPadding: 0,
                rightPadding: 0,
                topPadding: 0,
                bottomPadding: 0,
            },
        ],
    },
    {
        width: 1200,
        height: 628,
        state: [
            {
                id: 1,
                type: 'Image',
                opacity: 1,
                position: {
                    x: -5,
                    y: -5,
                },
                rotationAngle: 0,
                size: {
                    width: 1207,
                    height: 641,
                },
                crop: {
                    x: 0,
                    y: 0,
                    width: 1,
                    height: 1,
                },
                link: 'https://app.previewbuilder.com/background-pt-1.png',
                imageSizeMode: 'Contain',
                imageType: 'Uploaded',
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
            },
            {
                id: 2,
                type: 'Text',
                text: 'There will be your page title or description on your choice',
                fontFamily: 'Lilita One',
                fontWeight: 'Regular',
                color: '#ffffff',
                fontSize: 64,
                opacity: 1,
                position: {
                    x: 142,
                    y: 164,
                },
                rotationAngle: 0,
                size: {
                    width: 884,
                    height: 327,
                },
                verticalAlign: 'Middle',
                horizontalAlign: 'Center',
                horizontalLetterSpacing: 0,
                verticalLetterSpacing: 0,
                wrap: false,
                smartCrop: 'Resize',
                backgroundColor: 'none',
                backgroundOpacity: 1,
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
                leftPadding: 0,
                rightPadding: 0,
                topPadding: 0,
                bottomPadding: 0,
            },
            {
                id: 3,
                type: 'Text',
                text: 'you_site_domain.com',
                fontFamily: 'Poppins',
                fontWeight: 'Regular',
                color: '#ffffff',
                fontSize: 32,
                opacity: 1,
                position: {
                    x: 4,
                    y: 84,
                },
                rotationAngle: 0,
                size: {
                    width: 1192,
                    height: 65,
                },
                verticalAlign: 'Middle',
                horizontalAlign: 'Center',
                horizontalLetterSpacing: 0,
                verticalLetterSpacing: 0,
                wrap: false,
                smartCrop: 'Resize',
                backgroundColor: 'none',
                backgroundOpacity: 1,
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
                leftPadding: 0,
                rightPadding: 0,
                topPadding: 0,
                bottomPadding: 0,
            },
            {
                id: 4,
                type: 'Text',
                text: 'Any common info of your site here. Like "domain.com - best daily news ever"',
                fontFamily: 'Poppins',
                fontWeight: 'Regular',
                color: '#ffffff',
                fontSize: 27,
                opacity: 1,
                position: {
                    x: 349,
                    y: 494,
                },
                rotationAngle: 0,
                size: {
                    width: 510,
                    height: 122,
                },
                verticalAlign: 'Middle',
                horizontalAlign: 'Center',
                horizontalLetterSpacing: 0,
                verticalLetterSpacing: 0,
                wrap: false,
                smartCrop: 'Resize',
                backgroundColor: 'none',
                backgroundOpacity: 1,
                shadowColor: '#000000',
                shadowBlur: 0,
                shadowSpread: 0,
                shadowHorizontalOffset: 0,
                shadowVerticalOffset: 0,
                shadowOpacity: 1,
                borderColor: '#000000',
                borderWidth: 0,
                borderRadius: 0,
                borderStyle: 'solid',
                leftPadding: 0,
                rightPadding: 0,
                topPadding: 0,
                bottomPadding: 0,
            },
        ],
    },
];
